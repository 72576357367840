import React, { useEffect, useRef, useState } from "react"
import ApolloClient from "apollo-boost"
import gql from "graphql-tag"
import { useForm, Controller } from "react-hook-form"
import animateScrollTo from "animated-scroll-to"

import {
  eurasia,
  logo,
  freedomInsuranceLogo,
  amanatLogo,
  nomadLogo,
  tick,
} from "../assets"

import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import InputMask from "react-input-mask"
import Iframe from "react-iframe"

import "tachyons"
import "./saqtanu.css"

const client = new ApolloClient({
  uri: process.env.API_LIVE_URL,
  fetch,
})

const SaqtanuCalcNew = () => {
  const WITHOUT_GQL = false
  const ALERT_MESSAGE_ON_ADD_DRIVER =
    "Только на одну машину можно добавить несколько водителей"
  const ALERT_MESSAGE_ON_ADD_CAR =
    "Только на один ИИН можно добавить несколько машин"
  const ERRORS = {
    PHONE_NUMBER: {
      INVALID: {
        type: "invalidPhoneNumber",
        message: "Неверный номер телефона",
      },
      REQUIRED: { type: "required", message: "Номер телефона обязателен" },
    },
    DRIVERS: {
      INVALID: { type: "invalidIinNumber", message: "Неверный ИИН" },
      REQUIRED: { type: "required", message: "ИИН обязателен" },
    },
    CARS: {
      INVALID: {
        type: "invalidCarNumber",
        message: "Неверный номер автомобиля",
      },
      REQUIRED: { type: "required", message: "Номер автомобиля обязателен" },
    },
  }
  const REG_PHONE_NUMBER = /\+7\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}/i
  const REG_CAR_NUMBER_UGLY_TYPE_1 = /([a-zA-Z]{1})(\d{3})([a-zA-Z]{3})/i
  const REG_CAR_NUMBER_UGLY_TYPE_2 = /(\d{3})([a-zA-Z]{3})(\d{2})/i
  const REG_CAR_NUMBER = /([a-zA-Z]{1}\s\d{3}\s[a-zA-Z]{3})|(\d{3}\s[a-zA-Z]{3}\s\d{2})/i
  const [lastData, setLastData] = useState({
    insuranceApplicationId: undefined,
    phoneNumber: undefined,
    insurant: { iinNumber: undefined },
    drivers: [],
    cars: [],
  })
  const [currentData, setCurrentData] = useState({
    drivers: [{ iinNumber: "", response: "" }],
    cars: [{ number: "", response: "" }],
  })
  const [disabledFields, setDisabledFields] = useState({
    driversBlock: true,
    driversAddButton: true,
    drivers: [false],

    carsBlock: true,
    carsAddButton: true,
    cars: [false],
  })
  const [insuranceApplication, setInsuranceApplication] = useState(undefined)
  const [graphqlLErrors, setGraphqlErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      cars: [{ number: "", response: "" }],
      drivers: [{ iinNumber: "", response: "" }],
    },
  })
  const buttonCreateFfinsInsuranceApplicationRef = useRef(null)

  const lastDataMethods = {
    getLastIinNumber: ({ idx }) => {
      if (idx === 0) {
        return lastData.insurant.iinNumber
      }
      if (typeof lastData.drivers[idx - 1] !== "undefined") {
        return lastData.drivers[idx - 1]
      }
      return undefined
    },
    getLastCarNumber: ({ idx }) => {
      if (typeof lastData.cars[idx] !== "undefined") {
        return lastData.cars[idx]
      }
      return undefined
    },
  }
  const validate = {
    onSubmit: () => {
      const isValidPhoneNumber = REG_PHONE_NUMBER.test(watchPhoneNumberField)
      const isNonEmptyPhoneNumber = /[0-9()+]+/i.test(watchPhoneNumberField)
      if (
        !isValidPhoneNumber ||
        (lastData.phoneNumber && watchPhoneNumberField !== lastData.phoneNumber)
      ) {
        setError(
          "phoneNumber",
          isNonEmptyPhoneNumber
            ? ERRORS.PHONE_NUMBER.INVALID
            : ERRORS.PHONE_NUMBER.REQUIRED
        )
        return { isValidToSubmit: false }
      }

      const isValidDrivers = !watchAllDriversFields
        .map(({ iinNumber }, idx) => {
          const isValidIinNumber = /\d{12}/i.test(iinNumber)
          const isNonEmptyIinNumber = /\d+/i.test(iinNumber)
          if (idx === 0 && !isValidIinNumber) {
            setError(
              `drivers[${idx}]`,
              isNonEmptyIinNumber
                ? ERRORS.DRIVERS.INVALID
                : ERRORS.DRIVERS.REQUIRED
            )
            return false
          }
          if (idx !== 0 && !isValidIinNumber && isNonEmptyIinNumber) {
            setError(`drivers[${idx}]`, ERRORS.DRIVERS.INVALID)
            return false
          }
          return true
        })
        .some(valid => !valid)
      if (!isValidDrivers) {
        return { isValidToSubmit: false }
      }

      const isValidCars = !watchAllFields
        .map(({ number: carNumber }, idx) => {
          const isValidCarNumber = REG_CAR_NUMBER.test(carNumber)
          const isNonEmptyCarNumber = /[A-Za-z0-9]+/i.test(carNumber)
          if (idx === 0 && !isValidCarNumber) {
            setError(
              `cars[${idx}]`,
              isNonEmptyCarNumber ? ERRORS.CARS.INVALID : ERRORS.CARS.REQUIRED
            )
            return false
          }
          if (idx !== 0 && !isValidCarNumber && isNonEmptyCarNumber) {
            setError(`cars[${idx}]`, ERRORS.CARS.INVALID)
            return false
          }
          return true
        })
        .some(valid => !valid)
      if (!isValidCars) {
        return { isValidToSubmit: false }
      }

      return { isValidToSubmit: true }
    },
    onChangePhoneNumber: ({ value }) => {
      const isValidPhoneNumber = REG_PHONE_NUMBER.test(value)
      if (isValidPhoneNumber) {
        clearErrors("phoneNumber")
      }
      if (isValidPhoneNumber && disabledFields.driversBlock) {
        setDisabledFields({
          ...disabledFields,
          driversBlock: false,
          driversAddButton: false,
        })
      }
      if (!isValidPhoneNumber && !disabledFields.driversBlock) {
        setDisabledFields({
          ...disabledFields,
          driversBlock: true,
          driversAddButton: true,
          carsBlock: true,
          carsAddButton: true,
        })
      }

      return { isValidPhoneNumber }
    },
    onChangeIinNumber: ({ value, disable }) => {
      const isValidIinNumber = /\d{12}/i.test(value)
      if (disable && isValidIinNumber && disabledFields.carsBlock) {
        setDisabledFields({
          ...disabledFields,
          carsBlock: false,
          carsAddButton: false,
        })
      }
      if (disable && !isValidIinNumber && !disabledFields.carsBlock) {
        setDisabledFields({
          ...disabledFields,
          carsBlock: true,
          carsAddButton: true,
        })
      }

      return {
        isValidIinNumber,
      }
    },
    onChangeCarNumber: ({ value, idx }) => {
      const isValidCarNumber = REG_CAR_NUMBER.test(value)
      const isNonEmptyCarNumber = /\d+|[A-Za-z]+/i.test(value)
      const isFirstCarNumber = idx === 0

      return { isValidCarNumber }
    },
  }
  useEffect(() => {
    if (insuranceApplication) {
      animateScrollTo(
        buttonCreateFfinsInsuranceApplicationRef.current.offsetTop
      )
    }
  })

  const beautifyCarNumber = ({ number }) => {
    const carNumberType1 = REG_CAR_NUMBER_UGLY_TYPE_1.exec(number)
    const carNumberType2 = REG_CAR_NUMBER_UGLY_TYPE_2.exec(number)
    if (carNumberType1) {
      return `${carNumberType1[1]} ${carNumberType1[2]} ${carNumberType1[3]}`
    }
    if (carNumberType2) {
      return `${carNumberType2[1]} ${carNumberType2[2]} ${carNumberType2[3]}`
    }
    console.error(`undefined format of carNumber "${number}"`)

    return ""
  }
  const carNumberMaskBuilder = carNumber => {
    if (/^\d/i.test(carNumber)) {
      return "999 aaa 99"
    } else if (/^[A-Za-z]/i.test(carNumber)) {
      return "a 999 aaa"
    }
    return "*"
  }

  const onSubmit = async data => {
    const { isValidToSubmit } = validate.onSubmit()
    if (isValidToSubmit) {
      await calcFfinsInsuranceApplication()
    }
  }

  const onError = errors => {
    console.log(errors)
    validate.onSubmit()
  }

  const createFfinsInsuranceApplication = async () => {
    try {
      setIsLoading(true)

      const {
        data: {
          insuranceApplicationFfinsCreate: { formUrl, body },
        },
      } = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: insuranceApplication.id,
          },
        },
        mutation: gql`
          mutation insurancePriceCalculate(
            $input: InsuranceApplicationFfinsCreateInput = {
              insuranceApplicationId: ""
            }
          ) {
            insuranceApplicationFfinsCreate(input: $input) {
              formUrl
              body
            }
          }
        `,
      })

      const form = document.createElement("form")
      form.setAttribute("method", "post")
      form.setAttribute("action", formUrl)
      form.setAttribute("target", "view")

      body.split("&").forEach(param => {
        const [name, value] = param.split("=")
        const hiddenField = document.createElement("input")
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("name", name)
        hiddenField.setAttribute("value", value)
        form.appendChild(hiddenField)
      })

      document.body.appendChild(form)

      window.open("", "view")

      form.submit()
    } catch (error) {}

    setIsLoading(false)
  }

  const initFfinsInsuranceApplication = async ({ iinNumber, phoneNumber }) => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            phoneNumber,
            iinNumber,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsInit(
            $input: InsuranceApplicationFfinsInitInput = {
              phoneNumber: ""
              iinNumber: ""
            }
          ) {
            insuranceApplicationFfinsInit(input: $input) {
              insuranceApplicationId
              insurant {
                iinNumber
                response
              }
              drivers {
                iinNumber
                response
              }
              cars {
                number
                response
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsInit) {
        const insurant = result.data.insuranceApplicationFfinsInit.insurant
        const drivers = result.data.insuranceApplicationFfinsInit.drivers
        const cars =
          result.data.insuranceApplicationFfinsInit.cars.length > 0
            ? result.data.insuranceApplicationFfinsInit.cars.map(
                ({ number, response }) => ({
                  number: beautifyCarNumber({ number }),
                  response,
                })
              )
            : [{ number: "", response: "" }]
        setValue("drivers", [insurant, ...drivers])
        setValue("cars", cars)
        setCurrentData({
          ...currentData,
          drivers: [insurant, ...drivers],
          cars,
        })
        setDisabledFields({
          driversBlock: false,
          driversAddButton: false,
          drivers: [false, ...drivers.map(() => true)],
          carsBlock: false,
          carsAddButton: false,
          cars: cars.map((item, idx) => idx !== 0),
        })
        setLastData({
          ...lastData,
          insuranceApplicationId:
            result.data.insuranceApplicationFfinsInit.insuranceApplicationId,
          phoneNumber: watchPhoneNumberField,
          insurant,
          drivers,
          cars,
        })
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }
  const addDriverFfinsInsuranceApplication = async ({ driverIinNumber }) => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: lastData.insuranceApplicationId,
            driverIinNumber,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsDriverAdd(
            $input: InsuranceApplicationFfinsDriverAddInput = {
              insuranceApplicationId: ""
              driverIinNumber: ""
            }
          ) {
            insuranceApplicationFfinsDriverAdd(input: $input) {
              insuranceApplicationId
              insurant {
                iinNumber
                response
              }
              drivers {
                iinNumber
                response
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsDriverAdd) {
        const insurant = result.data.insuranceApplicationFfinsDriverAdd.insurant
        const drivers = result.data.insuranceApplicationFfinsDriverAdd.drivers
        setValue("drivers", [insurant, ...drivers])
        setCurrentData({ ...currentData, drivers: [insurant, ...drivers] })
        setDisabledFields({
          ...disabledFields,
          driversBlock: false,
          driversAddButton: false,
          drivers: [false, ...drivers.map(() => true)],
          carsBlock: false,
          carsAddButton: false,
        })
        setLastData({
          ...lastData,
          insuranceApplicationId:
            result.data.insuranceApplicationFfinsDriverAdd
              .insuranceApplicationId,
          insurant,
          drivers,
        })
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }
  const dropDriverFfinsInsuranceApplication = async ({ driverIinNumber }) => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: lastData.insuranceApplicationId,
            driverIinNumber,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsDriverDrop(
            $input: InsuranceApplicationFfinsDriverDropInput = {
              insuranceApplicationId: ""
              driverIinNumber: ""
            }
          ) {
            insuranceApplicationFfinsDriverDrop(input: $input) {
              insuranceApplicationId
              insurant {
                iinNumber
                response
              }
              drivers {
                iinNumber
                response
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsDriverDrop) {
        const insurant =
          result.data.insuranceApplicationFfinsDriverDrop.insurant
        const drivers = result.data.insuranceApplicationFfinsDriverDrop.drivers
        setValue("drivers", [insurant, ...drivers])
        setCurrentData({ ...currentData, drivers: [insurant, ...drivers] })
        setDisabledFields({
          ...disabledFields,
          driversBlock: false,
          driversAddButton: false,
          drivers: [false, ...drivers.map(() => true)],
          carsBlock: false,
          carsAddButton: false,
        })
        setLastData({
          ...lastData,
          insuranceApplicationId:
            result.data.insuranceApplicationFfinsDriverDrop
              .insuranceApplicationId,
          insurant,
          drivers,
        })
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }
  const addCarFfinsInsuranceApplication = async ({ carNumber }) => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: lastData.insuranceApplicationId,
            carNumber,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsCarAdd(
            $input: InsuranceApplicationFfinsCarAddInput = {
              insuranceApplicationId: ""
              carNumber: ""
            }
          ) {
            insuranceApplicationFfinsCarAdd(input: $input) {
              insuranceApplicationId
              cars {
                number
                response
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsCarAdd) {
        const cars =
          result.data.insuranceApplicationFfinsCarAdd.cars.length > 0
            ? result.data.insuranceApplicationFfinsCarAdd.cars.map(
                ({ number, response }) => ({
                  number: beautifyCarNumber({ number }),
                  response,
                })
              )
            : [{ number: "", response: "" }]
        setValue("cars", cars)
        setCurrentData({
          ...currentData,
          cars,
        })
        setDisabledFields({
          ...disabledFields,
          driversBlock: false,
          driversAddButton: false,
          cars: cars.map((item, idx) => idx !== 0),
          carsBlock: false,
          carsAddButton: false,
        })
        setLastData({
          ...lastData,
          insuranceApplicationId:
            result.data.insuranceApplicationFfinsCarAdd.insuranceApplicationId,
          cars,
        })
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }
  const dropCarFfinsInsuranceApplication = async ({ carNumber }) => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: lastData.insuranceApplicationId,
            carNumber,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsCarDrop(
            $input: InsuranceApplicationFfinsCarDropInput = {
              insuranceApplicationId: ""
              carNumber: ""
            }
          ) {
            insuranceApplicationFfinsCarDrop(input: $input) {
              insuranceApplicationId
              cars {
                number
                response
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsCarDrop) {
        const cars =
          result.data.insuranceApplicationFfinsCarDrop.cars.length > 0
            ? result.data.insuranceApplicationFfinsCarDrop.cars.map(
                ({ number, response }) => ({
                  number: beautifyCarNumber({ number }),
                  response,
                })
              )
            : [{ number: "", response: "" }]
        setValue("cars", cars)
        setCurrentData({
          ...currentData,
          cars,
        })
        setDisabledFields({
          ...disabledFields,
          driversBlock: false,
          driversAddButton: false,
          cars: cars.map((item, idx) => idx !== 0),
          carsBlock: false,
          carsAddButton: false,
        })
        setLastData({
          ...lastData,
          insuranceApplicationId:
            result.data.insuranceApplicationFfinsCarDrop.insuranceApplicationId,
          cars,
        })
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }
  const calcFfinsInsuranceApplication = async () => {
    setIsLoading(true)

    try {
      const result = await client.mutate({
        variables: {
          input: {
            insuranceApplicationId: lastData.insuranceApplicationId,
          },
        },
        mutation: gql`
          mutation insuranceApplicationFfinsPriceCalculate(
            $input: InsuranceApplicationFfinsPriceCalculateInput = {
              insuranceApplicationId: ""
            }
          ) {
            insuranceApplicationFfinsPriceCalculate(input: $input) {
              company
              insuranceApplication {
                id
                discountPercentage
                amount
                fullAmount
              }
            }
          }
        `,
      })

      if (result.data.insuranceApplicationFfinsPriceCalculate) {
        setInsuranceApplication(
          result.data.insuranceApplicationFfinsPriceCalculate
            .insuranceApplication
        )
      } else {
        setGraphqlErrors(result.errors)
        console.log(result.errors)
        console.log("set errors")
      }
    } catch (error) {
      setGraphqlErrors(error.graphQLErrors)
      console.error({ error })
    }

    setIsLoading(false)
  }

  const watchAllFields = watch("cars")
  const watchAllDriversFields = watch("drivers")
  const watchPhoneNumberField = watch("phoneNumber")

  const onChangePhoneNumber = async ({ value, onChange }) => {
    onChange(value)
    const { isValidPhoneNumber } = validate.onChangePhoneNumber({ value })
    const lastPhoneNumber = lastData.phoneNumber
    if (
      !WITHOUT_GQL &&
      isValidPhoneNumber &&
      lastPhoneNumber && lastPhoneNumber !== value
    ) {
      initFfinsInsuranceApplication({
        iinNumber: lastDataMethods.getLastIinNumber({ idx: 0 }),
        phoneNumber: value,
      })
    }
  }
  const onChangeFirstIinNumber = ({ value, onChange }) => {
    onChange(value)
    const { isValidIinNumber } = validate.onChangeIinNumber({
      value,
      disable: true,
    })
    const lastIinNumber = lastDataMethods.getLastIinNumber({ idx: 0 })
    if (
      !WITHOUT_GQL &&
      isValidIinNumber &&
      (!lastIinNumber || lastIinNumber !== value)
    ) {
      initFfinsInsuranceApplication({
        iinNumber: value,
        phoneNumber: watchPhoneNumberField,
      })
    }
  }
  const onChangeOtherIinNumber = ({ value, onChange, idx }) => {
    if (disabledFields.drivers[idx]) {
      return
    }

    onChange(value)
    const { isValidIinNumber } = validate.onChangeIinNumber({
      value,
      disable: false,
    })
    if (!WITHOUT_GQL && isValidIinNumber) {
      addDriverFfinsInsuranceApplication({ driverIinNumber: value })
    }
  }
  const onChangeCarNumber = ({ value, onChange, idx }) => {
    if (disabledFields.cars[idx]) {
      return
    }

    onChange(value)
    const { isValidCarNumber } = validate.onChangeCarNumber({ value, idx })

    if (!WITHOUT_GQL && isValidCarNumber) {
      addCarFfinsInsuranceApplication({ carNumber: value })
    }
  }

  const handleOnAddDriver = () => {
    if (currentData.cars.length > 1) {
      alert(ALERT_MESSAGE_ON_ADD_DRIVER)
    } else {
      setCurrentData({
        ...currentData,
        drivers: [...currentData.drivers, { iinNumber: "", response: "" }],
      })
      setDisabledFields({
        ...disabledFields,
        drivers: [...disabledFields.drivers, false],
      })
    }
  }
  const handleOnRemoveDriver = targetIdx => {
    const lastIinNumber = lastDataMethods.getLastIinNumber({ idx: targetIdx })
    if (WITHOUT_GQL || !lastIinNumber) {
      setCurrentData({
        ...currentData,
        drivers: currentData.drivers.filter((item, idx) => idx !== targetIdx),
      })
    } else {
      dropDriverFfinsInsuranceApplication({ driverIinNumber: lastIinNumber })
    }
  }
  const handleOnAddCar = () => {
    if (currentData.drivers.length > 1) {
      alert(ALERT_MESSAGE_ON_ADD_CAR)
    } else {
      setCurrentData({
        ...currentData,
        cars: [...currentData.cars, { number: "", response: "" }],
      })
      setDisabledFields({
        ...disabledFields,
        cars: [...disabledFields.cars, false],
      })
    }
  }
  const handleOnRemoveCar = targetIdx => {
    const lastCarNumber = lastDataMethods.getLastCarNumber({ idx: targetIdx })
    if (WITHOUT_GQL || !lastCarNumber) {
      setCurrentData({
        ...currentData,
        cars: currentData.cars.filter((item, idx) => idx !== targetIdx),
      })
    } else {
      dropCarFfinsInsuranceApplication({ carNumber: lastCarNumber })
    }
  }

  return (
    <div className="sq-layout">
      <noscript>
        <Iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PQSCVLC"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></Iframe>
      </noscript>
      <Helmet>
        <script src={withPrefix("js/googletagmanager.js")} />
      </Helmet>
      {isLoading && <div id="cover-spin"></div>}

      <header className="sq-header flex justify-between">
        <a href="/">
          <img
            alt="logo"
            title="logo"
            src={logo}
            className="br2 mb0 header__logo"
          />
        </a>
        <nav className="sq-nav dn db-l">
          <a className="sq-nav_item sq-nav_item--active">ОГПО</a>
          <a className="sq-nav_item">КАСКО</a>
        </nav>
        <a className="sq-nav_burger"></a>
      </header>

      <div className="sq-sections">
        <section className="sq-section sq-section--calc flex flex-column flex-row-l justify-between">
          <div className="w-50-l mb0-l mb5">
            <h1 className="sq-h1">Калькулятор ОГПО</h1>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="sq-form_item">
                <label htmlFor="phoneNumber">Номер телефона</label>
                <Controller
                  control={control}
                  name="phoneNumber"
                  placeholder="+7 ("
                  defaultValue={""}
                  render={({ onChange, onBlur, value, name }) => (
                    <InputMask
                      mask="+7 (999) 999 99 99"
                      name={name}
                      maskChar=" "
                      className="sq-form_input"
                      onChange={e =>
                        onChangePhoneNumber({ value: e.target.value, onChange })
                      }
                      onBlur={onBlur}
                      value={value}
                      inputMode="tel"
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <p className="f6 ma0 red">{errors.phoneNumber.message}</p>
                )}
              </div>

              {currentData.drivers.map(({ iinNumber, response }, idx) => (
                <div className="sq-form_item" key={`driverIdx-${idx}`}>
                  {idx === 0 && <label htmlFor="IIN">ИИН страхователя</label>}
                  <div className="sq-form_itemInner">
                    <Controller
                      control={control}
                      name={`drivers[${idx}].iinNumber`}
                      inputMode="tel"
                      defaultValue={iinNumber}
                      render={({ onChange, onBlur, value, name }) => (
                        <InputMask
                          disabled={
                            disabledFields.driversBlock ||
                            disabledFields.drivers[idx]
                          }
                          mask="999999999999"
                          name={name}
                          maskChar=" "
                          className="sq-form_input sq-form_input--iin"
                          onChange={e =>
                            idx === 0
                              ? onChangeFirstIinNumber({
                                  value: e.target.value,
                                  onChange,
                                })
                              : onChangeOtherIinNumber({
                                  value: e.target.value,
                                  onChange,
                                  idx,
                                })
                          }
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                    {idx === 0 ? (
                      <span
                        className={`sq-plus${
                          disabledFields.driversAddButton ? "-disabled" : ""
                        }`}
                        onClick={() =>
                          disabledFields.driversAddButton
                            ? () => {}
                            : handleOnAddDriver({})
                        }
                      ></span>
                    ) : (
                      <span
                        className="sq-minus"
                        onClick={() => handleOnRemoveDriver(idx)}
                      ></span>
                    )}
                  </div>
                  {response && <p className="sq-form_more_info">{response}</p>}
                  {errors.drivers && errors.drivers[idx] && (
                    <p className="f6 ma0 red">{errors.drivers[idx].message}</p>
                  )}
                </div>
              ))}

              {currentData.cars.map(({ number, response }, idx) => (
                <div
                  className="sq-form_item sq-form_item--carnum"
                  key={`carNumber-${idx}`}
                >
                  {idx === 0 && (
                    <label htmlFor="carNumber">Номер автомобиля</label>
                  )}
                  <div className="sq-form_itemInner">
                    <Controller
                      control={control}
                      name={`cars[${idx}].number`}
                      defaultValue={number}
                      render={({ onChange, onBlur, value, name }) => (
                        <InputMask
                          disabled={
                            disabledFields.carsBlock || disabledFields.cars[idx]
                          }
                          mask={carNumberMaskBuilder(
                            watchAllFields && watchAllFields[idx]
                              ? watchAllFields[idx].number
                              : ""
                          )}
                          name={name}
                          maskChar=" "
                          className="sq-form_input"
                          onChange={e =>
                            onChangeCarNumber({
                              value: e.target.value,
                              onChange,
                              idx,
                            })
                          }
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                    {idx === 0 ? (
                      <span
                        className={`sq-plus${
                          disabledFields.carsAddButton ? "-disabled" : ""
                        }`}
                        onClick={() =>
                          disabledFields.carsAddButton
                            ? () => {}
                            : handleOnAddCar()
                        }
                      ></span>
                    ) : (
                      <span
                        className="sq-minus"
                        onClick={() => handleOnRemoveCar(idx)}
                      ></span>
                    )}
                  </div>
                  {response && <p className="sq-form_more_info">{response}</p>}
                  {errors.cars && errors.cars[idx] && (
                    <p className="f6 ma0 red">{errors.cars[idx].message}</p>
                  )}
                </div>
              ))}

              {graphqlLErrors
                .map(graphqlError => graphqlError.message)
                .join("<br />")}

              <button className="sq-btn" type="submit">
                Узнать стоимость
              </button>
            </form>
          </div>
          <div className="sq-calc-res w-50-l">
            {insuranceApplication ? (
              <div className="flex flex-column justify-start h-100">
                <p className="sq-text-l">Лучшее предложение:</p>
                <div>
                  <img
                    alt="Freedom Insurance"
                    title="Freedom Insurance"
                    src={freedomInsuranceLogo}
                  />
                </div>
                <div className="sq-res_desc">
                  <p className="sq-text">
                    Стоимость:
                    <span className="b ml2">
                      {insuranceApplication.amount / 100} тг
                    </span>
                  </p>
                  <div>
                    <p className="relative pl4">
                      <img className="sq-tick absolute" src={tick} />{" "}
                      Моментальные выплаты
                    </p>
                    <p className="relative pl4">
                      <img className="sq-tick absolute" src={tick} /> Рассрочка
                      платежа
                    </p>
                    <p className="relative pl4">
                      <img className="sq-tick absolute" src={tick} /> Розыгрыш
                      20 авто
                    </p>
                  </div>
                  <button
                    ref={buttonCreateFfinsInsuranceApplicationRef}
                    className="sq-btn"
                    onClick={createFfinsInsuranceApplication}
                  >
                    Выбрать
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex-l flex-column justify-start h-100 dn">
                <p className="sq-text-l w-70 mb5-l">
                  Получите лучшие предложения от лидеров страхового рынка
                </p>
                <div className="flex flex-column">
                  <div>
                    <img
                      alt="Freedom Insurance"
                      title="Freedom Insurance"
                      src={freedomInsuranceLogo}
                    />
                  </div>
                  <div>
                    <img alt="Amananat" title="Amanat" src={amanatLogo} />
                  </div>
                  <div>
                    <img alt="Nomad" title="Nomad" src={nomadLogo} />
                  </div>
                  <div>
                    <img alt="Eurasia" title="Eurasia" src={eurasia} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      <footer className="sq-footer">
        <a className="mr5">Оферта</a>
        <a
          className="underline"
          href="https://id.mkb.kz/#/esbd/home"
          target="_blank"
        >
          Проверить страховку в ЕСБД
        </a>
      </footer>
    </div>
  )
}

export default SaqtanuCalcNew
